<template>
    <b-sidebar
            id="add-new-user-sidebar"
            :visible="isSidebarOpen"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            shadow
            backdrop
            no-header
            right
            @change="(visible) => { if(!visible) resetForm();}"
            @shown="initData()"

    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    {{ action == 'editing'? $t('label_edit') : $t('label_add_client') }}
                </h5>

                <feather-icon
                        class="ml-1 cursor-pointer"
                        icon="XIcon"
                        size="16"
                        @click="hide"
                />

            </div>

            <!-- Form -->
            <validation-observer
                    :ref="PREFIX + '_FORM'"
                    #default="{ handleSubmit, invalid }"
                    class="p-2"
                    tag="form"
            >

                <validation-provider
                        #default="validationContext"
                        :name="$t('label_first_name')"
                        rules="required"
                        tag="div"
                        slim
                >
                    <b-form-group
                            :label="$t('label_first_name')"

                    >
                        <b-form-input

                                v-model="itemData.first_name"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                :placeholder="$t('label_first_name')"
                        />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Username -->
                <validation-provider
                        #default="validationContext"
                        :name="$t('label_last_name')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_last_name')"

                    >
                        <b-form-input

                                v-model="itemData.last_name"
                                :placeholder="$t('label_last_name')"
                                :state="getValidationState(validationContext)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationContext"
                        :name="$t('label_phone')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_phone')"

                    >
                        <b-form-input

                                v-model="itemData.phone"
                                :state="getValidationState(validationContext)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Email -->
                <validation-provider
                        #default="validationContext"
                        :name="$t('label_email_address')"
                        rules="required|email"
                        slim
                >
                    <b-form-group
                            :label="$t('label_email_address')"

                    >
                        <b-form-input

                                v-model="itemData.email"
                                :state="getValidationState(validationContext)"
                                trim
                        />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <!-- Deal_type -->
                <validation-provider
                        #default="validationContext"
                        :name="$t('label_deal_type')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_deal_type')"

                            :state="getValidationState(validationContext)"
                    >
                        <v-select
                                v-model="itemData.deal_type_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="item_deal_types"
                                :reduce="val => val.id"
                                label="name"
                                :clearable="false"
                                :placeholder="$t('label_select')"

                        >
                            <template v-slot:option="option">
                                <span class="">{{option.name}}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span class="">{{option.name}}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Consultant -->
                <validation-provider

                        #default="validationProps"
                        :name="$t('label_consultant_leading_case')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_consultant_leading_case')"
                    >

                        <infinity-scroll
                                         v-model="itemData.consultant_id"
                                         selected-prop="id"
                                         url="/consultant"
                                         :placeholder="$t('label_select')"
                                         :default-selection="chosenConsultant"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>


                <!-- Agent -->
                <validation-provider

                        #default="validationProps"
                        :name="$t('label_recommended')"
                        rules=""
                        slim
                >
                    <b-form-group :label="$t('label_recommended')"
                    >

                        <infinity-scroll
                                v-model="itemData.agent_id"
                                selected-prop="id"
                                url="/agent"
                                :placeholder="$t('label_select')"
                                :default-selection="chosenAgent"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationContext"
                        :name="$t('label_related_users')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_related_users')"
                            :state="getValidationState(validationContext)"
                    >

                        <infinity-scroll
                                :disabled="auth_user.role == 'consultant'"
                                :query-params="auth_user.role == 'consultant'? {type:'contact_related_users', role:'consultant'} : {}"
                                v-model="itemData.users"
                                selected-prop="id"
                                :multiple="true"
                                :url="auth_user.role == 'consultant'? '/role_access_users/1' : 'contact_allowed_users'"
                                :placeholder="$t('label_select')"
                                :default-selection="chosenUsers"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="action == 'editing' && $can('edit', 'deal.tab_details') && $can('edit', 'contact.related_agreements' )"
                        #default="validationContext"
                        :name="$t('label_choose_related_deal')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_choose_related_deal')"

                            :state="getValidationState(validationContext)"
                    >

                                <infinity-scroll
                                        v-model="itemData.deals"
                                        selected-prop="deal_id"
                                        :multiple="true"
                                        url="/deal"
                                        :placeholder="$t('label_select')"
                                        :default-selection="chosenDeals"
                                >
                                    <template #label="{item}">{{$t('label_deal') + ' ' + item.deal_number}}</template>

                                </infinity-scroll>

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                    >
                        {{ action == 'editing'? $t('label_save') : $t('label_add') }}
                    </b-button>
                    <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click.prevent="hide"
                    >
                        {{ $t('label_cancel') }}

                    </b-button>
                </div>

            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
    import {
        BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
    } from 'bootstrap-vue'

    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import  infinityScroll from '@/views/components/infinityScroll'

    import vSelect from 'vue-select'

    import { CONTACT_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            vSelect,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            infinityScroll
        },

        model: {
            prop: 'isSidebarOpen',
            event: 'update:is-sidebar-open',
        },
        props: ['isSidebarOpen','editedItem'],
        data() {
            return {
                PREFIX,

                action: 'adding',

                blankItemData: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    consultant_id: null,
                    agent_id: null,
                    deal_type_id: null,
                    deals: [],
                    users: []
                },
                itemData: {},

                item_deal_types: [],
                item_consultants: [],
                item_agents: [],
                item_related_deals: [],

                chosenConsultant:false,
                chosenAgent:false,
                chosenDeals: [],
                chosenUsers: [],
                auth_user: this.authUser()

            }
        },

        methods: {
            initData(){

                if(this.editedItem){

                    this.action = 'editing';
                    let item = Object.assign({},this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }

                    this.chosenDeals = this.editedItem.deals.map((el)=>{el.deal_number = el.number; el.deal_id = el.id; return el;});
                    item.deals = this.editedItem.deals.pluck('id');

                    this.chosenUsers = this.editedItem.users;
                    item.users = this.editedItem.users.pluck('id');

                    this.itemData = item;

                    this.chosenConsultant = this.editedItem.consultant;

                    this.chosenAgent = this.editedItem.agent;


                } else {
                    this.action = 'adding';
                    this.itemData =  Object.assign({},this.blankItemData);

                    if(this.auth_user.role == 'consultant') {
                        this.async('get', '/role_access_users/1', {params:{role:'consultant', type:'contact_related_users'}}, function (res) {
                            this.chosenUsers = res.data;
                            this.itemData.users = this.chosenUsers.pluck('id');
                        });
                    }


                }

            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
                this.$emit('update:is-sidebar-open', false)
            },

            onSubmit() {
                let self = this;

                if (this.action == 'editing') {
                    this.$store.dispatch('app/editContact', {itemData:this.itemData, vm: this})
                        .then(() => {
                            self.$emit('refetch-data');
                            self.$emit('item-updated', this.itemData);
                            self.$emit('update:is-sidebar-open', false);
                        })
                } else {
                    this.$store.dispatch('app/addContact', {itemData:this.itemData, vm: this})
                        .then((r) => {
                            self.$emit('item-added', r.data.item);
                            // self.$emit('refetch-data')
                            self.$emit('update:is-sidebar-open', false)
                        })
                }
            },

        },
        created(){
            this.async('get', '/select_options', {params:{options:['deal_subjects']}}, function(res){

                this.item_deal_types = res.data.deal_subjects
            });
        }
    }
</script>


