<template>
    <b-modal
            :id="modalRef? modalRef : PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{$t('label_arrange_meeting')}}
        </template>
        <template #default="{ hide }">
        <!-- Form -->
        <validation-observer
                #default="{ handleSubmit, invalid }"
                :ref="PREFIX + '_FORM'"
                tag="form"
                class="p-1"
        >
            <validation-provider
                    v-if="showAll"
                    #default="validationProps"
                    :name="$t('label_contact')"
                    rules="required"
                    slim
            >
                <b-form-group
                        :label="$t('label_potential_client')"

                >
                    <infinity-scroll
                            v-model="itemData.contact_id"
                            selected-prop="id"
                            url="/contact"
                            :multiple="false"
                            :placeholder="$t('label_select')"
                            :default-selection="chosenContact"
                    >
                        <template #label="{item}">{{item.first_name + ' ' + item.last_name}}</template>

                    </infinity-scroll>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>


                <validation-provider
                        #default="validationProps"
                        :name="$t('label_consultant')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_consultant')"

                    >
                        <infinity-scroll
                                :disabled="!$can('view','consultant.tab_details')"
                                v-model="itemData.consultant_id"
                                selected-prop="id"
                                url="/consultant"
                                :multiple="false"
                                :placeholder="$t('label_select')"
                                :default-selection="chosenConsultant"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>


                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>



                <validation-provider
                        #default="validationProps"
                        :name="$t('label_solicitor')"
                        rules=""
                        slim
                >
                    <b-form-group
                            :label="$t('label_solicitor')"

                    >
                        <infinity-scroll

                                selected-prop="id"
                                url="/solicitor"
                                :multiple="true"
                                :placeholder="$t('label_select')"

                                v-model="itemData.solicitor_ids"
                                :default-selection="chosenSolicitors"
                        >
                            <template #label="{item}">{{item.name}}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider

                        #default="validationProps"
                        :name="$t('label_meeting_type')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_meeting_type')"

                    >
                        <v-select
                                v-model="itemData.meeting_type_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="meetingTypes"
                                :reduce="val => val.id"
                                label="name"
                                :clearable="true"
                                :placeholder="$t('label_select')"

                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                            <template v-slot:selected-option="option">
                                <!--<span :class="option.icon"></span>-->
                                <span >{{ $t(option.name) }}</span>
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


            <b-row>
                <b-col cols="12" md="6">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_from')"
                            rules="required"
                            class="mr-1 flex-grow-1"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_date_from')"

                        >

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.start_at"

                                    @input="itemData.start_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_time')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_time')"

                        >
                            <custom-date-picker
                                    :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    placeholder="00:00"
                                    display-format="HH:mm"
                                    format="HH:mm"
                                    v-model="itemData.start_time"
                                    type="time"
                            >
                                <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                    <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit" v-text="$t('label_accept')"/>
                                </template>
                                <template v-slot:cancel-btn="{ vm, color, lang }">
                                    <button type="button" :style="{ color }" @click="vm.visible = false" v-text="$t('label_cancel')"/>
                                </template>
                                <div slot="label"><feather-icon  icon="ClockIcon" size="18"/></div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="6">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_till')"
                            rules="required"
                            class="mr-1 flex-grow-1"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_date_till')"

                        >

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"
                                    :value="itemData.deadline_at"

                                    @input="itemData.deadline_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_time')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_time')"

                        >
                            <custom-date-picker
                                    :class="{'date--picker': true , 'time--picker':true, 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    placeholder="00:00"
                                    display-format="HH:mm"
                                    format="HH:mm"
                                    v-model="itemData.deadline_time"
                                    type="time"
                            >
                                <template v-slot:submit-btn="{ vm, canSubmit, color, submit, lang }">
                                    <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit" v-text="$t('label_accept')"/>
                                </template>
                                <template v-slot:cancel-btn="{ vm, color, lang }">
                                    <button type="button" :style="{ color }" @click="vm.visible = false" v-text="$t('label_cancel')"/>
                                </template>
                                <div slot="label"><feather-icon  icon="ClockIcon" size="18"/></div>
                            </custom-date-picker>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>

            <validation-provider
                    #default="validationProps"
                    :name="$t('label_send_email_to_client')"
                    slim
            >
                <b-form-group
                        label=""

                >

                    <b-form-checkbox
                            id="share-term"
                            v-model="itemData.send_to_contact"
                            value="1"
                            unchecked-value="0"
                    >
                        <label for="share-term">{{ $t('label_send_email_to_client') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>


            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_send_sms_to_client')"
                    slim
            >
                <b-form-group
                        label=""
                >
                    <b-form-checkbox
                            id="notify_via_sms"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.notify_via_sms"
                    >
                        <label for="notify_via_sms">{{ $t('label_send_sms_to_client') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>

            <div v-if="itemData.notify_via_sms == 1">
                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_sms_pattern')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_sms_pattern')"
                    >
                        <infinity-scroll
                                selected-prop="id"
                                :multiple="false"
                                url="/sms_patterns"
                                :placeholder="$t('label_select')"
                                :close-on-select="false"
                                v-model="itemData.sms_pattern_id"
                                :default-selection="chosenSmsPattern"
                        >
                            <template #label="{item}">{{ item.pattern_name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>
            </div>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_sync_with_google_calendar_users')"
                        slim
                >
                    <b-form-group
                            label=""

                    >

                        <b-form-checkbox
                                id="meeting-google-sync"
                                v-model="itemData.google_sync"
                               value="1"
                                unchecked-value="0"
                        >
                            <label for="meeting-google-sync">{{ $t('label_sync_with_google_calendar_users') }}</label>
                        </b-form-checkbox>
                    </b-form-group>
                </validation-provider>



                <validation-provider

                        #default="validationProps"
                        :name="$t('label_description')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_description')"

                    >

                        <quill-editor
                                class="meet-quill"
                                required
                                v-model="itemData.description"
                                :options="snowOption"
                        ></quill-editor>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(modalRef? modalRef : PREFIX + '-modal')">
                        {{$t('label_close')}}</button>

                    <button type="submit"  @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_submit')}}</button>
                </div>

        </validation-observer>


        </template>
    </b-modal>
</template>

<script>

    import { ValidationProvider, ValidationObserver} from 'vee-validate'
    import  infinityScroll from '@/views/components/infinityScroll'
    import { quillEditor } from 'vue-quill-editor'

    import {
         BFormTextarea,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormCheckbox
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'


    import { CONTACT_PREFIX as MODULE_PREFIX, MEETING_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormCheckbox,
            quillEditor,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll
        },
        props:['editedItem', 'contactId', 'showAll', 'modalRef'],

        data() {
            return {
                PREFIX,
                MODULE_PREFIX,
                action: 'adding',

                snowOption: {
                    theme: 'snow',
                    modules: {
                        toolbar: null
                    }
                },

                blankItemData: {
                    id: null,
                    consultant_id: null,
                    solicitor_ids: [],
                    contact_id: null,
                    deadline_time: formatDate(Math.ceil(this.$moment.now()/1000), 'HH:mm', 'unix'),
                    deadline_at: formatDate(Math.ceil(this.$moment.now()/1000), 'DD/MM/YYYY', 'unix'),
                    start_time: formatDate(Math.ceil(this.$moment.now()/1000), 'HH:mm', 'unix'),
                    start_at: formatDate(Math.ceil(this.$moment.now()/1000), 'DD/MM/YYYY', 'unix'),
                    description: '',
                    meeting_type_id: '',
                    sms_pattern_id: null,
                    google_sync:1,
                    send_to_contact: 0,
                    notify_via_sms: 0
                },

                itemData: {},

                solicitors: [],
                meetingTypes:[],

                chosenSolicitors:[],
                chosenConsultant:false,
                chosenContact:false,
                loggedUser: {},

                // smsPatternCategoryId: 0,
                chosenSmsPattern: null
            }
        },
        methods:{
            initData(){

                this.getModalData();

                let editedItem = this.editedItem;
                this.loggedUser = this.authUser();

                if(editedItem){

                    this.action = 'editing';
                    let item = Object.assign({},this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }
                    item.solicitor_ids =  editedItem.related_solicitors_ids? editedItem.related_solicitors_ids.split(',') : [];


                    item.start_at = formatDate(editedItem.start_at, 'DD/MM/YYYY', 'unix');
                    item.start_time = formatDate(editedItem.start_at, 'HH:mm', 'unix');
                    item.deadline_at = formatDate(editedItem.deadline_at, 'DD/MM/YYYY', 'unix');
                    item.deadline_time = formatDate(editedItem.deadline_at, 'HH:mm', 'unix');
                    item.meeting_type_id = editedItem.meeting_type_id;



                    this.itemData = item;
                    this.chosenSolicitors = editedItem.solicitors;
                    this.chosenSmsPattern = editedItem.sms_pattern;

                    if(this.loggedUser.role != 'consultant') {
                        this.async('get', 'consultant/' + item.consultant_id, {}, function(res){
                            this.chosenConsultant = res.data;
                        });
                    }

                        if(this.showAll){
                            this.async('get', 'contact/' + editedItem.contact_id, {}, function(res){
                                this.chosenContact = res.data;
                            });
                        }

                } else {

                    this.action = 'adding';
                    this.itemData =  Object.assign({},this.blankItemData);

                    if(this.contactId){
                        this.itemData.contact_id =  this.contactId;
                    }

                }
                if(this.loggedUser.role == 'consultant') {
                    this.chosenConsultant = this.loggedUser;
                    this.itemData.consultant_id = this.loggedUser.id;
                }

            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.chosenSolicitors = [];
                this.chosenConsultant = false;
                this.chosenContact = false;
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){

                if(this.action == 'adding') {
                    this.async('post', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s', this.itemData, function(resp){
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.modalRef? this.modalRef: this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/'+this.itemData.id, this.itemData, function(resp){
                        this.$emit('item-edited',{});
                        this.$bvModal.hide(this.modalRef? this.modalRef : this.PREFIX + '-modal');
                    });

                }

            },
            getModalData(){
                this.async('get', '/select_options', {params:{options:['meeting_types']}}, function(res){
                    this.meetingTypes  =  res.data.meeting_types;
                });

            }
        },
        created(){

            if(!this.showAll){
                this.blankItemData.contact_id = this.$router.currentRoute.params.id;
            }

        }

    }
</script>

<style lang="scss"> //!!! for editor
@import '@core/scss/vue/libs/quill.scss';
.meet-quill .ql-editor{
    min-height: 100px;
}
/*.meet-quill .ql-formats{*/
    /*display: none !important;*/
     /*}*/
.meet-quill .ql-toolbar{
    padding: 0 !important;
    border-bottom: none !important;
}
</style>
