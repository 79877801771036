<template>

    <b-card
            no-body
            class="mb-0"
    >

        <div class="m-2">

            <b-row>

                <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >

                    <v-select
                            v-model="tableData.perPage"
                            :searchable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="tableData.perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mr-1"
                    >
                        <template v-slot:no-options="option">
                            {{$t('label_no_items')}}
                        </template>
                    </v-select>


                    <column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>

                </b-col>

                <!-- Search -->
                <b-col
                        cols="12"
                        md="6"
                >

                    <div class="d-flex align-items-center justify-content-end">

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1"
                                :placeholder="$t('label_search')+'...'"
                        />
                        <b-button v-if="!isReferral && $can('edit', 'contact.tab_details')"
                                variant="primary"
                                @click="isSidebarOpen = true; editedItem = false"
                        >
                            <span class="text-nowrap">{{ $t('label_new') }}</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <!--***********************************************************TABLE*********************************************-->

     <div class="px-2 table-container-wrap">
         <!--sticky-header-->
         <b-table
                 ref="table"
                 class="position-relative transited-table"
                 :busy="isBusy"
                 no-border-collapse
                 :items="tableItems"
                 responsive
                 :fields="selectedColumns"
                 primary-key="id"
                 :sort-by.sync="tableData.sortBy"
                 no-local-sorting
                 :sort-desc.sync="tableData.sortDesc"
                 show-empty
                 :tbody-transition-props="{ name: 'flip-list'}"
                 :empty-text="$t('label_no_table_data')"
         >

             <template #head()="data">
                 <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
             </template>

             <!-- Column: Status -->
             <template #cell(name)="data">

                 <router-link v-if="$can('view', 'contact.tab_details')" :to="{ name: 'contact-details', params: { id: data.item.id } }">
                     {{data.item.first_name + ' ' + data.item.last_name }}
                 </router-link>
                 <template v-else>
                     {{data.item.first_name + ' ' + data.item.last_name }}
                 </template>
             </template>
             <template #cell(status)="data">
                 <b-badge
                         pill
                         :variant="`light-${resolveContactStatusVariant(data.item.status_id)}`"
                         class="text-capitalize"
                 >
                     {{ data.item.status? $t(data.item.status.translation_index) : '--' }}
                 </b-badge>
             </template>


             <template #cell(consultant_id)="data">
                 <router-link
                         v-if="$can('view', 'consultant.tab_details')"
                         :to="{ name: 'consultant-details', params: { id: data.item.consultant_id } }"
                 >
                     {{ data.item.consultant? data.item.consultant.name : ''}}
                 </router-link>
                 <span v-else> {{ data.item.consultant? data.item.consultant.name : '' }}</span>
             </template>

             <template #cell(agent_id)="data">
                <template v-if="data.item.agent_id">
                    <router-link
                            v-if="$can('view', 'agent.tab_details')"
                            :to="{ name: 'agent-details', params: { id: data.item.agent_id } }"
                    >
                        {{ data.item.agent? data.item.agent.name : '' }}
                    </router-link>
                    <span v-else> {{ data.item.agent? data.item.agent.name : '' }}</span>
                </template>
                 <span v-else>--</span>
             </template>

             <template #cell(referral_user_id)="data">
                 {{ data.item.referral_user_id ? $t('label_marketing'): '' }}
             </template>

             <!-- Column: Actions -->
             <template #cell(actions)="data">

                 <b-dropdown
                         dropleft
                         boundary="window"
                         variant="link"
                         no-caret
                         @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                         @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                         :right="$store.state.appConfig.isRTL"
                 >

                     <template #button-content>
                         <feather-icon
                                 icon="MoreVerticalIcon"
                                 size="16"
                                 class="align-middle text-body"
                         />
                     </template>

                     <template v-if="$can('edit', 'contact.tab_details')">
                         <b-dropdown-item disabled>
                             <span class="badge badge-light-primary d-block">{{ $t('label_change_status') }}</span>
                         </b-dropdown-item>
                         <b-dropdown-item v-if="data.item.status_id != 109" @click="changeContactStatus(data.item.id,109)">
                             <span class="align-middle ml-50">{{ $t('label_in_process') }}</span>
                         </b-dropdown-item>
                         <b-dropdown-item v-if="data.item.status_id != 111" @click="changeContactStatus(data.item.id,111)">
                             <span class="align-middle ml-50">{{ $t('label_refuse') }}</span>
                         </b-dropdown-item>
                     </template>
                     <!--<b-dropdown-divider></b-dropdown-divider>-->
                     <b-dropdown-item disabled>
                         <div class="divider">
                             <div class="divider-text">{{ $t('label_action') }}</div>
                         </div>
                         <!--<span class="badge badge-light-warning d-block"></span>-->
                     </b-dropdown-item>

                     <b-dropdown-item :to="{name: 'agreement_add', params: { contact_id: data.item.id }}" v-if="$can('edit', 'agreement.tab_details')" >
                         <feather-icon icon="FileTextIcon"/>
                         <span class="align-middle ml-50">{{ $t('label_generate_contract') }}</span>
                     </b-dropdown-item>

                     <b-dropdown-item v-if="$can('edit', 'contact.tab_meetings')" @click="meetingForId = data.item.id;$bvModal.show(MEETING_PREFIX + '-modal')">
                         <feather-icon icon="UsersIcon"/>
                         <span class="align-middle ml-50">{{ $t('label_arrange_meeting') }}</span>
                     </b-dropdown-item>

                     <template v-if="$can('edit', 'contact.tab_details')">
                         <b-dropdown-item @click="editedItem = data.item; isSidebarOpen = true;">
                             <feather-icon icon="EditIcon"/>
                             <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                         </b-dropdown-item>

                         <b-dropdown-item @click="deleteItem(data.item.id)">
                             <feather-icon icon="TrashIcon"/>
                             <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                         </b-dropdown-item>

                     </template>
                 </b-dropdown>
             </template>

         </b-table>
         <div class="mx-2 mb-2">
             <b-row>

                 <b-col
                         cols="12"
                         sm="6"
                         class="d-flex align-items-center justify-content-center justify-content-sm-start"
                 >
                     <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                 </b-col>
                 <!-- Pagination -->
                 <b-col
                         cols="12"
                         sm="6"
                         class="d-flex align-items-center justify-content-center justify-content-sm-end"
                 >

                     <b-pagination
                             v-model="tableData.currentPage"
                             :total-rows="tableTotal"
                             :per-page="tableData.perPage"
                             first-number
                             last-number
                             class="mb-0 mt-1 mt-sm-0"
                             prev-class="prev-item"
                             next-class="next-item"
                     >
                         <template #prev-text>
                             <feather-icon
                                     icon="ChevronLeftIcon"
                                     size="18"
                             />
                         </template>
                         <template #next-text>
                             <feather-icon
                                     icon="ChevronRightIcon"
                                     size="18"
                             />
                         </template>
                     </b-pagination>

                 </b-col>

             </b-row>
         </div>
     </div>

        <meeting-modal :contact-id="meetingForId"></meeting-modal>
        <item-processing-modal @item-added="$router.push({name: 'contact-details', params:{id:$event.id}})" :edited-item="editedItem" :is-sidebar-open.sync="isSidebarOpen" @refetch-data="refreshDataTable"></item-processing-modal>


    </b-card>

</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import meetingModal from './../modals/processMeetingModal'
    import columnChooser from '@/views/components/columnChooser'
    import itemProcessingModal from './../modals/itemProcessingModal'
    import {resolveContactStatusVariant} from './../moduleHelper'

    import {CONTACT_PREFIX as PREFIX , MEETING_PREFIX } from './../moduleHelper'

    export default {
        components: {

            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            meetingModal,
            vSelect,
            columnChooser,
            itemProcessingModal
        },
        props: [
            'filterData', 'isReferral'
        ],
        data() {
            return {
                PREFIX,
                MEETING_PREFIX,

                resolveContactStatusVariant,
                editedItem:false,
                isBusy:false,
                isSidebarOpen: false,
                meetingForId:false,
                selectedColumns:[],
                columnsDefs: [
                    {
                        label: '№',
                        key: 'id',
                        formatter: function (v, k, item) {
                            return v
                        },
                        sortable: true,
                        thClass: 'hidden',
                        tdClass: 'hidden',
                    },
                    {
                        label: 'label_date_of_adding',
                        key: 'created_at',
                        sortable: true,
                        formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }
                    },
                    {
                        label: 'label_name_and_surname',
                        key: 'name',
                        sortable: true
                    },
                    {label: 'label_email', key: 'email', sortable: true},
                    {label: 'label_origin', key: 'referral_user_id', sortable: true},
                    {label: 'label_status', key: 'status', sortable: true},
                    {label: 'label_consultant', key: 'consultant_id', sortable: true},
                    {label: 'label_agent', key: 'agent_id', sortable: true},
                    {label: 'label_action', key: 'actions'},
                ],

                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [ 10, 25, 50, 100],
                },

            }
        },

        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        methods: {

            changeContactStatus(module_id, status_id) {
                this.async('put', '/'+this.PREFIX+'/change_status/' + module_id, {status_id: status_id}, function (resp) {
                    this.refreshDataTable();

                });

            },
            refreshDataTable: function () {

                this.isBusy = true;
                    this.async('get', '/'+this.PREFIX, {params: {
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage-1)*this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                            created_at_from: this.filterData.dateFromFilter,
                            created_at_to: this.filterData.dateToFilter,
                        status_id: this.filterData.statusFilter,
                            consultant: this.filterData.consultantFilter,
                        agent_id: this.filterData.agentFilter,
                        user_id: this.filterData.userFilter,
                            marketing_only: this.filterData.refLinkFilter,

                    }}, function (resp) {

                        this.tableItems = resp.data.items;
                        this.tableTotal = resp.data.total;
                        this.isBusy = false;
                    });
            },
            deleteItem(id) {

                this.confirmDeleting((result) => {
                    this.$store.dispatch('app/deleteContact', id)
                        .then(() => {
                            this.refreshDataTable();
                        })
                })
            },

        },
        created() {
            let self = this;
            this.refreshDataTable();
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (!self.isRefferal && obj.key == 'actions' && !self.$can('edit', 'contact.tab_details')) {
                    return false;
                } else if(self.isRefferal && obj.key == 'actions' && !self.$can('edit', 'partner.referral')){
                            return false;
                }
                return true;
            });
        },

    }

</script>
